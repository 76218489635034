import styled from 'styled-components';

import { LiveIframe } from 'components/LiveIframe';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem 0 0 0;
`;

export const Finance = () => {
  return (
    <Wrapper>
      <LiveIframe
        styleKind="mainSection"
        path={`/general_data/finance`}
        queryParams={ {} }
      />
    </Wrapper>
  );
};
