import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from 'components/pages/Home';
import { Page404 } from 'components/pages/Page404';
import { PageLayout } from 'components/layout/PageLayout';
import { Login } from 'components/pages/auth/Login';
import { useAuthToken } from 'state/AuthState';
import { ForgotPassword } from 'components/pages/auth/ForgotPassword';
import { ResetPassword } from 'components/pages/auth/ResetPassword';
import { Employees } from 'components/pages/employees/Employees';
import { Customers } from 'components/pages/customers/Customers';
import { EmployeeDetail } from 'components/pages/employees/EmployeeDetail';
import { EmployeeEdit } from 'components/pages/employees/EmployeeEdit';
import { CustomerDetail } from 'components/pages/customers/CustomerDetail';
import { CustomerEdit } from 'components/pages/customers/CustomerEdit';
import { CustomersAdd } from 'components/pages/customers/CustomersAdd';
import { CalendarExceptions } from 'components/pages/calendar-exceptions/CalendarExceptions';
import { CalendarExceptionDetail } from 'components/pages/calendar-exceptions/CalendarExceptionDetail';
import { CalendarExceptionEdit } from 'components/pages/calendar-exceptions/CalendarExceptionEdit';
import { CalendarExceptionAdd } from 'components/pages/calendar-exceptions/CalendarExceptionAdd';
import { CustomerCategories } from 'components/pages/customer-categories/CustomerCategories';
import { CustomerCategoryDetail } from 'components/pages/customer-categories/CustomerCategoryDetail';
import { CustomerCategoryEdit } from 'components/pages/customer-categories/CustomerCategoryEdit';
import { CustomerCategoryAdd } from 'components/pages/customer-categories/CustomerCategoryAdd';
import { CustomerOrganizationAdd } from 'components/pages/customer-organizations/CustomerOrganizationAdd';
import { CustomerOrganizationEdit } from 'components/pages/customer-organizations/CustomerOrganizationEdit';
import { Passengers } from 'components/pages/passengers/Passengers';
import { PassengerDetail } from 'components/pages/passengers/PassengerDetail';
import { PassengerEdit } from 'components/pages/passengers/PassengerEdit';
import { PassengersAdd } from 'components/pages/passengers/PassengersAdd';
import { TourDetail } from 'components/pages/tours/TourDetail';
import { Tours } from 'components/pages/tours/Tours';
import { CustomerOrdersAdd } from 'components/pages/customers/orders/CustomerOrdersAdd';
import { CustomerOrderEdit } from 'components/pages/customers/orders/CustomerOrderEdit';
import { CustomerTourplan } from 'components/pages/customers/CustomerTourplan';
import { CustomerDetailInvoiceDetails } from 'components/pages/customers/CustomerDetailInvoiceDetails';
import { UserManagement } from 'components/pages/user-management/UserManagement';
import { Icons } from 'components/icons/Icons';
import { BaseData } from 'components/pages/base-data/BaseData';
import { Finance } from 'components/pages/finance/Finance';
import { useQuery } from '@tanstack/react-query';
import { IUser } from 'api/models/User';
import { useRecoilState } from 'recoil';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { UserMeState } from 'state/UserMeState';
import { AxiosError } from 'axios';
import axiosDefault from 'axios';

import { LiveIframe } from 'components/LiveIframe';

axiosDefault.interceptors.request.use(function (config) {
  config.headers['X-URL'] = window.location.href;
  return config;
});

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const authToken = useAuthToken();
  const { logout, getUserMe } = useAuthAPI();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_userMe, setUserMe] = useRecoilState(UserMeState);
  const location = useLocation();

  const {
    data: profile,
    error,
    refetch,
  } = useQuery({
    queryKey: ['userMe'],
    refetchInterval: 1000 * 60 * 5,
    retryDelay: 350,
    queryFn: async () => {
      const { data } = await getUserMe();

      return data as IUser;
    },
  });

  useEffect(() => {
    const axiosError = error as AxiosError;
    if (axiosError) {
      if (axiosError.response?.status !== 500) {
        console.log('error', error);
        setUserMe(null);
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (profile) {
      setUserMe(profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (authToken) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, location.pathname]);

  if (authToken) {
    return <>{children}</>;
  }

  return <Navigate replace to={'/login'} />;
};

function App() {
  const list: any = [
    {
      path: '/',
      element: <PageLayout />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },

        /* pages */
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          ),
        },
        {
          path: '/employees/old',
          element: (
            <ProtectedRoute>
              <LiveIframe styleKind="mainSection" path={`/employees/old`} queryParams={{}} />
            </ProtectedRoute>
          ),
        },
        {
          path: '/employees/:id/edit',
          element: (
            <ProtectedRoute>
              <EmployeeEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: '/employees/:id',
          element: (
            <ProtectedRoute>
              <EmployeeDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '/employees',
          element: (
            <ProtectedRoute>
              <Employees />
            </ProtectedRoute>
          ),
        },

        {
          path: '/customers/:customerId/orders/add',
          element: (
            <ProtectedRoute>
              <CustomerOrdersAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers/:customerId/orders/:id/edit',
          element: (
            <ProtectedRoute>
              <CustomerOrderEdit />
            </ProtectedRoute>
          ),
        },

        {
          path: '/customers/add',
          element: (
            <ProtectedRoute>
              <CustomersAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers/:id/edit',
          element: (
            <ProtectedRoute>
              <CustomerEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers/:id/tourplan',
          element: (
            <ProtectedRoute>
              <CustomerTourplan />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers/:customerId/invoice/:invoiceId',
          element: (
            <ProtectedRoute>
              <CustomerDetailInvoiceDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers/:id',
          element: (
            <ProtectedRoute>
              <CustomerDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customers',
          element: (
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customer-organizations/add',
          element: (
            <ProtectedRoute>
              <CustomerOrganizationAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customer-organizations/:id/edit',
          element: (
            <ProtectedRoute>
              <CustomerOrganizationEdit />
            </ProtectedRoute>
          ),
        },

        {
          path: '/calendar-exceptions',
          element: (
            <ProtectedRoute>
              <CalendarExceptions />
            </ProtectedRoute>
          ),
        },
        {
          path: '/calendar-exceptions/add',
          element: (
            <ProtectedRoute>
              <CalendarExceptionAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/calendar-exceptions/:id/edit',
          element: (
            <ProtectedRoute>
              <CalendarExceptionEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: '/calendar-exceptions/:id',
          element: (
            <ProtectedRoute>
              <CalendarExceptionDetail />
            </ProtectedRoute>
          ),
        },

        {
          path: '/customer-categories',
          element: (
            <ProtectedRoute>
              <CustomerCategories />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customer-categories/add',
          element: (
            <ProtectedRoute>
              <CustomerCategoryAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customer-categories/:id/edit',
          element: (
            <ProtectedRoute>
              <CustomerCategoryEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: '/customer-categories/:id',
          element: (
            <ProtectedRoute>
              <CustomerCategoryDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '/calendar-exceptions',
          element: (
            <ProtectedRoute>
              <CustomerCategories />
            </ProtectedRoute>
          ),
        },
        {
          path: '/tours/:id',
          element: (
            <ProtectedRoute>
              <TourDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '/tours',
          element: (
            <ProtectedRoute>
              <Tours />
            </ProtectedRoute>
          ),
        },

        {
          path: '/passengers/add',
          element: (
            <ProtectedRoute>
              <PassengersAdd />
            </ProtectedRoute>
          ),
        },
        {
          path: '/passengers/:id/edit',
          element: (
            <ProtectedRoute>
              <PassengerEdit />
            </ProtectedRoute>
          ),
        },
        {
          path: '/passengers/:id',
          element: (
            <ProtectedRoute>
              <PassengerDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '/passengers',
          element: (
            <ProtectedRoute>
              <Passengers />
            </ProtectedRoute>
          ),
        },

        {
          path: '/stammdaten',
          element: (
            <ProtectedRoute>
              <BaseData />
            </ProtectedRoute>
          ),
        },

        {
          path: '/finanzen',
          element: (
            <ProtectedRoute>
              <Finance />
            </ProtectedRoute>
          ),
        },

        {
          path: '/user-management',
          element: (
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          ),
        },
        {
          path: '/404',
          element: <Page404 />,
        },
      ],
    },
  ];

  if (process.env.NODE_ENV === 'development') {
    list.push({
      path: '/icons',
      element: <Icons />,
    });
  }

  /* fallback route */
  list.push({
    path: '/*',
    element: <Navigate to={'/404'} />,
  });

  const router = createBrowserRouter(list);

  return <RouterProvider router={router} />;
}

export default App;
