import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

interface IGetCalendarExceptionByIdProps {
  id: string;
}

export const useCalendarExceptionAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getCalendarExceptionById = (props: IGetCalendarExceptionByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/calendar-exceptions/${props.id}`, { ...authHeader });
    });
  };

  return {
    getCalendarExceptionById
  };
};
