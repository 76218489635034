import { FC, useEffect, useMemo } from 'react';
import { useAuthToken } from 'state/AuthState';
import { useNavigate } from 'hooks/useNavigate';

type LiveIframeProps = {
  path: string;
  queryParams?: any;
  styleKind: string;
  onClose?: () => void;
};

const mainStyle = {
  width: '100%', // XXX
  border: 0,
  opacity: 1,
  padding: 0,
  visibility: 'visible',
  background: 'rgba(0,0,0,0)',
  overflow: 'scroll-y',
};

const iframeStyle = {
  mainSection: {
    ...mainStyle,
    height: 'calc(100vh - 420px)',
    margin: '1rem 0 0 0',
  },
  mainSectionFull: {
    ...mainStyle,
    height: '100vh',
  },
  mainSectionBreadcrumb: {
    ...mainStyle,
    height: 'calc(100vh - 76px)'
  },
  dialog: { // This is used for LiveIframeDialog
    ...mainStyle,
    margin: 0,
    borderRadius: 10,
    zIndex: 15000,
    height: '100%',
    width: '100%'
  }
} as any;

export const LiveIframe: FC<LiveIframeProps> = (props) => {
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const navigateReplace = useNavigate(true); // problem: FF detects this as a popup!

  const { path, styleKind, queryParams, onClose } = props;

  const identity = useMemo(() => `${Math.random()}`, []);
  const callback = (evt: any) => {
    if (evt.data.sourceIdentity == identity) {
      if (evt.data.what == 'dismiss') {
        if (onClose) {
          evt.preventDefault();
          onClose();
        }
      } else if (evt.data.what == 'navigate') {
        if (evt.data.replace) {
          navigateReplace(evt.data.path);
        } else {
          navigate(evt.data.path);
        }
        evt.preventDefault();
      } else if (evt.data.what == 'redirect') {
        window.location = evt.data.path;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', callback);
    return () => {
      window.removeEventListener('message', callback);
    };
  });

  const url = useMemo(() => {
    const result = new URL(`/live/${path}`, window.location.href);
    result.hash = '#' + identity;
    for (const property in queryParams) {
      result.searchParams.append(property, queryParams[property]);
    }
    if (authToken) {
      result.searchParams.append('authToken', authToken);
    }

    return result
  }, [path, queryParams, identity, authToken]);

  return <iframe src={url.href} style={iframeStyle[styleKind]} />;
};
