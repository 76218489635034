import { LiveIframe } from 'components/LiveIframe';

interface ICustomerDetailsAccountingProps {
  customerId: string;
  invoiceId: string;
}
export default function CustomerDetailsAccountingInvoice(props: ICustomerDetailsAccountingProps) {
  const { customerId, invoiceId } = props;

  return (
    <LiveIframe
      styleKind="mainSectionFull"
      path={`/customers/${customerId}/invoices/${invoiceId}`}
    />
  );
}
