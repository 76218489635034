import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

interface ICreateBookingCompletionProps {
  timestamp: string;
  comment: string | null;
}

interface IGetBookingCompletionsProps {
  offset: number;
  limit: number;
}

interface IGetBookingCompletionByIdProps {
  id: string;
}

interface IUpdateBookingCompletionProps extends ICreateBookingCompletionProps {}

export const useBookingCompletionsAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const getBookingCompletions = (props: IGetBookingCompletionsProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams([
        ['offset', `${props.offset || 0}`],
        ['limit', `${props.limit || 25}`],
      ]).toString();
      return axios.get(`/api/booking-completions?${qs}`, { ...authHeader });
    });
  };

  return {
    getBookingCompletions
  };
};
