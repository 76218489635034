// General
import axios from 'axios';

// State
import { useAuthHeader } from 'state/AuthState';

// Types
import { IAddress } from 'api/models/Address';
import { IPhoneNumber } from 'api/models/PhoneNumber';
import { useErrorHandler } from 'api/controllers/ErrorHandler';
import { getCurrentDayDate } from 'utils/dateUtils';

interface ICreateEmployeeProps {
  internalId: string;
  firstName: string;
  lastName: string;

  emailAddress: string;

  jobTitle: string;
  drivingLicense: string;
  region: string;

  comment: string;

  hasSkillScalamobil: boolean;
  hasSkillSupport: boolean;
  isDrivingTauga: boolean;

  address?: Partial<IAddress>;
  phoneNumbers?: Partial<IPhoneNumber>[];
  imageId?: string;
}

interface IGetEmployeesProps {
  offset: number;
  limit: number;
}

interface IGetEmployeeByIdProps {
  id: string;
}

interface IUpdateEmployeeProps extends Partial<ICreateEmployeeProps> {
  //
}

interface IGetEmployeeAbsencesProps {
  id: string;
  startDate?: string;
  endDate?: string;
}

export interface IPostEmployeeAbsenceProps {
  type: string; // 'disease; | 'vacation'
  startDate: string;
  endDate?: string;
}

export interface IPostEmployeeTimeSupplementProps {
  type: string;
  timestamp: string;
  amount: number;
  comment: string | null;
}

interface IGetEmployeeAvailabilities {
  ids: string[];
  timestamp?: string;
  direction?: string;
}

interface IGetEmployeeInvoices {
  id: string;
  year: string;
}

interface IGetEmployeeInvoiceByIdProps {
  employeeId: string;
  invoiceId: string;
}

export const useEmployeesAPI = () => {
  const authHeader = useAuthHeader();
  const errorHandler = useErrorHandler();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const deleteEmployee = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/employees/${id}`, { ...authHeader });
    });
  };

  const restoreEmployee = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/employees/${id}/restore`, {}, { ...authHeader });
    });
  };

  const getEmployeeTimeSupplements = (props: IGetEmployeeAbsencesProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(
        props.startDate
          ? props.endDate
            ? [
                ['startDate', props.startDate],
                ['endDate', props.endDate],
              ]
            : [['startDate', props.startDate]]
          : props.endDate
          ? [['endDate', props.endDate]]
          : [],
      );
      return axios.get(`/api/employees/${props.id}/time-supplements?${qs}`, { ...authHeader });
    });
  };

  const postEmployeeTimeSupplement = (id: string, props: IPostEmployeeTimeSupplementProps) => {
    return checkAuth()
      .then(() => {
        return axios.post(`/api/employees/${id}/time-supplements`, props, { ...authHeader });
      })
      .catch(errorHandler);
  };

  const getEmployeeAbsences = (props: IGetEmployeeAbsencesProps) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(
        props.startDate
          ? props.endDate
            ? [
                ['startDate', props.startDate],
                ['endDate', props.endDate],
              ]
            : [['startDate', props.startDate]]
          : props.endDate
          ? [['endDate', props.endDate]]
          : [],
      );
      return axios.get(`/api/employees/${props.id}/absences?${qs}`, { ...authHeader });
    });
  };

  const getEmployeeAvailabilities = (props: IGetEmployeeAvailabilities) => {
    return checkAuth().then(() => {
      const qs = new URLSearchParams(Object.entries(props)).toString();
      return axios.get(`/api/employees/availabilities?${qs}`, { ...authHeader });
    });
  };

  const uploadFile = (file: File) => {
    return checkAuth().then(() => {
      const formData = new FormData();
      formData.append('file', file);
      return axios.put(`/api/files`, formData, { ...authHeader });
    });
  };

  return {
    deleteEmployee,
    restoreEmployee,
    getEmployeeTimeSupplements,
    postEmployeeTimeSupplement,
    getEmployeeAbsences,
    getEmployeeAvailabilities,
    uploadFile,
  };
};
