import { useParams } from 'react-router-dom';
import { ContentPageLayout } from 'components/common/atoms/Layout';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';

import { LiveIframe } from 'components/LiveIframe';

export const CustomerOrganizationEdit = () => {
  const { id } = useParams();

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />

      <LiveIframe
        styleKind="mainSection"
        path={`/customer_organizations/form`}
        queryParams={ { id: id } }
      />
    </ContentPageLayout>
  );
};
