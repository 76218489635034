import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { CustomerFilterState } from 'state/CustomerFilterState';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import { ButtonSecondary } from 'components/common/inputs/Button';
import { Plus } from 'components/icons/Plus';
import { CustomerFilter } from 'components/content/customers/CustomerFilter';
import { Link } from 'react-router-dom';
import { useAuthHelp } from 'state/AuthState';
import { CustomerOrganizationsBridge } from '../../../bridges/CustomerOrganizationsBridge';
import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { UiState } from 'state/UiState';
import { ReplaceIn, PickFromModel } from 'api/models';

import { LiveIframe } from 'components/LiveIframe';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  --list-template: 5rem 24rem 7rem 10rem 8rem 1fr;
`;

type OurCustomerOrganization = ReplaceIn<PickFromModel<ICustomerOrganization, 'name' | 'description'>, { id: number }>;

export const CustomersList = () => {
  const uiState = useRecoilValue(UiState);

  const [searchParams] = useRecoilState(CustomerFilterState);

  const authHelp = useAuthHelp();
  const customerOrganizationsBridge = new CustomerOrganizationsBridge(authHelp);

  const [customerOrganizations, setCustomerOrganizations] = useState<OurCustomerOrganization[]>([]);

  const fetchCustomerOrganizations = async () => {
    try {
      const { data } = await customerOrganizationsBridge.getAll();
      setCustomerOrganizations(data);
    } catch (error) {
      console.error('Error fetching customer organizations:', error);
    }
  };

  useEffect(() => {
    fetchCustomerOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ContentPageHead title={`Einrichtungen`} listName="customerList" hasTabbar={true}>
        <FlexWrapper>
          <Link to={'/customers/add'}>
            <ButtonSecondary>
              <Plus /> Einrichtung anlegen
            </ButtonSecondary>
          </Link>

          <CustomerFilter customerOrganizations={customerOrganizations} />
        </FlexWrapper>
      </ContentPageHead>

      <LiveIframe
        styleKind="mainSection"
        path={`/customers`}
        queryParams={{ ...searchParams, as: uiState.customerList.activeTab === 'tile' ? 'tile' : 'list' }}
      />
    </Wrapper>
  );
};
