import { ContentPageLayout } from 'components/common/atoms/Layout';

import { LiveIframe } from 'components/LiveIframe';

export const CalendarExceptionAdd = () => {
  return (
    <ContentPageLayout>
      <LiveIframe
        styleKind="mainSection"
        path={`/calendar_exceptions_form`}
        queryParams={ {} }
      />
    </ContentPageLayout>
  );
};
