import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { ContentPageLayout } from 'components/common/atoms/Layout';

import { LiveIframe } from 'components/LiveIframe';

export const CustomerOrganizationAdd = () => {
  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          },
        ]}
      />

      <LiveIframe
        styleKind="mainSection"
        path={`/customer_organizations/form`}
        queryParams={ {} }
      />
    </ContentPageLayout>
  );
};
