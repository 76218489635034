import { useParams } from 'react-router-dom';
import { ContentPageLayout } from 'components/common/atoms/Layout';

import { LiveIframe } from 'components/LiveIframe';

export const CalendarExceptionEdit = () => {
  const { id } = useParams();

  return (
    <ContentPageLayout>
      <LiveIframe
        styleKind="mainSection"
        path={`/calendar_exceptions_form`}
        queryParams={ {id: id} }
      />
    </ContentPageLayout>
  );
};
