import { FC } from 'react';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';

import { LiveIframeDialog } from 'components/LiveIframeDialog';

interface EmployeeAbsenceDialogProps {
  id: string;
  item?: IEmployeeAbsence;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
}

export const EmployeeAbsenceDialog: FC<EmployeeAbsenceDialogProps> = (props) => {
  const { id, item, setOpen, onSuccess } = props;

  return <LiveIframeDialog
    path={`/employees/${id}/employee-absences/form`}
    queryParams={item?.id ? {id: item.id}: {}}
    onClose={() => {
      if (onSuccess) { onSuccess() };
      setOpen(false);
    }}
  />;
};
