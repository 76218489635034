// General
import { FC } from 'react';

// Types
import { ICustomer } from 'api/models/Customer';

import { LiveIframe } from 'components/LiveIframe';

export const CustomerDetailsContacts: FC<{ customer: ICustomer; updateHook: () => void }> = (props) => {
  const { customer } = props;

  return <LiveIframe
        styleKind="mainSection"
        path={`/customers/${customer.id}/contacts`}
        queryParams={
          {}
        }
      />
};
