import { FC } from 'react';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

import { LiveIframe } from 'components/LiveIframe';

export const EmployeeDetailsInformation: FC<{ employee: EmployeeResponseItem }> = (props) => {
  const { employee } = props;

  return (
    <div>
      <LiveIframe
        styleKind="mainSectionFull"
        path={`/employees/${employee.id}/infos`}
        queryParams={{}}
      />
    </div>
  );
};
