import { FC } from 'react';
import styled from 'styled-components';
import { LiveIframe } from 'components/LiveIframe';

const Dialog = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

type LiveIframeDialogProps = {
  path: string;
  queryParams?: any;
  onClose?: () => void;
};

export const LiveIframeDialog: FC<LiveIframeDialogProps> = (props) => {
  return (
    <Dialog onClose={props.onClose}>
      <LiveIframe path={props.path} queryParams={props.queryParams} onClose={props.onClose} styleKind="dialog" />
    </Dialog>
  );
};
