import styled from 'styled-components';

import { LiveIframe } from 'components/LiveIframe';

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 2.25rem var(--content-indent) 4.75rem;
  margin-bottom: 2.25rem;
`;

export const CalendarExceptions = () => {
  return (
    <Wrapper>
      <LiveIframe
        styleKind="mainSectionFull"
        path={`/calendar_exceptions`}
        queryParams={ {} }
      />
    </Wrapper>
  );
};
